// export const environment = {
//   production: true
// };

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyD7boGDgyqUh6Aggip6F2LbBTYy6OKaRHY",
    projectId: "edutin-push-notifications",
    messagingSenderId: "419389701075",
    appId: "1:419389701075:web:07dda7268f53bf87be8d26"
  },
  firebaseTest: {
    apiKey: "AIzaSyAh77vK1e1xocAZt4nusOWYkSA0P4g9b64",
    authDomain: "pushnotification-c9ca1.firebaseapp.com",
    projectId: "pushnotification-c9ca1",
    storageBucket: "pushnotification-c9ca1.appspot.com",
    messagingSenderId: "348342166350",
    appId: "1:348342166350:web:752d96acfb70869a99741f"
  },
  openIA: {
    //apiKey: "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",
    apiKey: "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",
  },
  domain: 'https://develop.edutin.com',
  appDomain: 'https://design.edutin.com',
  urlAPI: 'https://api.edutin.com/b/d',
  urlAPIBusiness: 'https://api.edutin.com/bus/d',
  urlAPIAffiliate: 'https://api.edutin.com/f/d',
  urlAPIDesigner: 'https://api.edutin.com/d/d',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
