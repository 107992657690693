import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer2,
  forwardRef,
  AfterContentInit,
  ViewChild,
  HostListener,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AppService } from "src/app/app.service";

@Component({
  selector: "combobox",
  templateUrl: "combobox.component.html",
  styleUrls: ["combobox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxComponent),
      multi: true,
    },
  ],
})
export class ComboboxComponent
  implements ControlValueAccessor, AfterContentInit, OnChanges
{
  @ViewChild("comboBox") comboBox: ElementRef;

  @Input() items: any[];
  @Input() displayProperty: string;
  @Input() startItem: any = null;
  @Input() disabled: boolean = false;
  @Input() placeholder: any = "Seleccione una opción";
  @Input() loading: boolean = false;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() bottomEmit = new EventEmitter<any>();

  public selectedItem: any;
  public showOptions: boolean = false;
  public userInput: string = "";
  public selectedFocusItemIndex: number = null;
  public matchingIndex;
  public timerId;
  public id: any = this.generateUUID();

  scrollSubject = new Subject<MouseEvent>();


  writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedItem = value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //check if startItem has changed
     if (changes.startItem && !changes.startItem.firstChange) {
       this.selectedItem = changes.startItem.currentValue;
     }
   }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implementa lógica para deshabilitar el componente si es necesario
    // Por ejemplo, puedes agregar atributos o clases CSS para deshabilitarlo visualmente.
    if (isDisabled) {
      this.renderer.setAttribute(
        this.elementRef.nativeElement,
        "disabled",
        "true"
      );
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, "disabled");
    }
  }

  // Implementación de ControlValueAccessor
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private _appService: AppService) {
    
    this.scrollSubject
    .pipe(debounceTime(200)) // Ajusta el tiempo de debounce según tus necesidades
    .subscribe((event) => {
      this.onScrollDebounced(event);
    });
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    if (this.items) {
      if (this.startItem) {
        this.selectedItem = this.startItem;
      } else {
        this.selectedItem = this.displayProperty
          ? {
              id: "unselected",
              [this.displayProperty]: this.placeholder,
            }
          : this.placeholder;
      }
    }
  }

  showOptionsToggle() {
    if (!this.disabled) {
      this.showOptions = !this.showOptions;
    }
  }

  selectItem(item: any) {
    this.selectedItem = item;
    this.showOptions = false;
    this.onChange(item); // Notificar cambios al formulario
    this.onTouched(); // Marcar como tocado
    this.itemSelected.emit(item);
  }

  onComboBoxKeyPress(event: KeyboardEvent) {
    console.log;
    if (event.key.length === 1) {
      this.userInput += event.key;
      this.focusMatchingItem();
    } else if (event.key === "Backspace") {
      this.userInput = this.userInput.slice(0, -1);
      this.focusMatchingItem();
    }

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.userInput = "";
    }, 1000);
  }

  focusMatchingItem() {
    const oldItem = document.getElementById(
      "option-" + this.id + "-" + this.matchingIndex
    );
    if (oldItem) {
      oldItem.classList.remove("focused");
    }

    const matchingIndex = this.items.findIndex((item) =>
      (this.displayProperty ? item[this.displayProperty] : item)
        .toLowerCase()
        .includes(this.userInput.toLowerCase())
    );

    if (matchingIndex !== -1) {
      const item = document.getElementById(
        "option-" + this.id + "-" + matchingIndex
      );
      item.classList.add("focused");
      item.focus();
      this.comboBox.nativeElement.focus();
      this.matchingIndex = matchingIndex;
    }

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.userInput = '';
    }, 1000);
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: MouseEvent) {
    this.scrollSubject.next(event);
  }

  onScrollDebounced(event) {
    let box = event.target
    let suma = box.offsetHeight + event.target.scrollTop;
    let sbHeight = box.scrollHeight;
    if (suma >= sbHeight - sbHeight * 0.20) {
      this.bottomEmit.emit();
    }
  }

  generateUUID(): string {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }
}
