import { Component } from '@angular/core';
import { AppService } from './app.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  user_routes = [
    'login',
    'add',
    'forgetpwd',
    'confirm',
    'resend',
    'reset',
    'resend_activation',
    'confirmForgotPassword'
  ];

  constructor(
    public _router: Router,
    public _appService: AppService,
    public _authService: AuthService,
  ) {
    document.documentElement.setAttribute("data-browser", navigator.userAgent);

    this._router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this._appService.url_route = event.url.replace('/', '').split('/')[0].split('?')[0];
        }
      }
    );

    this._authService.isUserAuthenticated().subscribe();
  }

  ngOnInit() {

  }

  goToLink(url: string): void {
    window.open(url, "_blank");
  }

  verifyAuthenticatedUserEmail() {
    //Verificar si lo que es aquí es el correo o es el telefono
    const { email, phone_number } = this._appService.cognitoUserAuthenticated.signInUserSession.idToken.payload;

    if (email != null && email != '') {
      this._appService.verifyEmail = email;
      this._router.navigate(['/user/confirm']);
    }
  }
}
