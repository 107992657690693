import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategyService } from './custom-preloading-strategy.service';
import { AuthGuard } from './guards/auth.guard';
import { EditGuard } from './guards/edit.guard';


export const APP_ROUTES: Routes = [
  { path: 'user',data: { preload: true, loadAfterSeconds: 1 }, loadChildren: () => import('./auth/user.module').then(mod => mod.AuthModule), canActivate: [AuthGuard] },

  { path: 'designer', loadChildren: () => import('./designer/designer.module').then(mod => mod.DesignerModule), canActivate:[EditGuard] },

  { path: '', redirectTo: 'designer', pathMatch: 'full' },
  { path: '**', redirectTo: 'designer', pathMatch: 'full' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: CustomPreloadingStrategyService });
