import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class EditGuard  {

  constructor(private _appService: AppService, private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { valid, session } = this._appService.getUserData();
    // Cognito User
    if (valid) {
      // User with session
      if (session == 1) {
        // Show nav bar and hide footer
        this._appService.loadNavBar = true;
        this._appService.loadFooter = false;
        // Init gets nav bar
        this._appService.startGetsNavBar();
        // Go
        return true;
      }
      // User session in -1
      return this._router.parseUrl('/user/accounts');
    }
    // Cognito User error
    return this._router.parseUrl('/user/login');
  }
}
