
// export const UrlPhpApi = 'https://login.edutin.com';
export const UrlPhpApi = 'https://edutin.com';
export const UrlLambdaApidd = 'https://api.edutin.com/d/d';
export const UrlLambdaApi = 'https://api.edutin.com/b/p';
// export const UrlLambdaApi = 'https://37lyp7qtzf.execute-api.us-east-1.amazonaws.com/dev';

export interface GlobalData {

  actions: boolean, // si el usuario está logeado ? true : false;
  certification: { Certification: Certification, User: User }, //data del certificado y el usuario
  course: Course,
  initialSource: any,
  // learn: Learn | Lesson,
  initialLesson: any,
  lesson: any,
  modules: any,
  position: { module: any, lesson: any, isLastOne: boolean },
  source: any,
  uploads: any,
  url_route: string,
  file: any,
  width_size: number,
  height_size: number,
  img_viewer_src: any,
  evaluations: any,
}

export interface Certification {
  alerta: string,
  calificacion: any,
  calification: any,
  cierre: string | number,
  clase_id: string | number,
  count_revisions: string | number,
  curso_id: string | number,
  duracion: string | number,
  duracion_min: string | number,
  estado: string | number,
  fecha: string,
  fecha_inscripcion: string,
  id: string | number,
  language_id: string | number,
  payment_id: string | number,
  question_success: any,
  question_total: any,
  rating: string | number,
  tipo: string | number,
  tour: any,
  user_id: string | number,
}

interface User {
  active: string | number,
  className: string,
  email: string,
  id: string | number,
  image: string,
  language_id: string | number,
  name: string,
  role: string,
  tour: string,
  username: string
}

interface Course {
  duration: string | number,
  state: string | number,
  id: string | number,
  nombre: string,
  name?:string,
  nombre_certificado: string,
  type: string | number,
  user_id: string | number,
  slug: string
}

export interface Learn {
  Clase: {
    course_id: string | number,
    descripcion: string,
    id: string | number,
    module_id: string | number,
    order: string | number,
    titulo: string
  },
  Curso: Course,
  Recent: [{
    certification_id: string | number,
    evaluation: string | number,
    id: string | number,
    lesson_id: string | number,
    position: string | number,
    user_id: string | number,
  }],
  Source: any[]

}

interface Recent {
  course_id: string | number
  evaluation: string | number
  fecha: string,
  id: string | number,
  lesson_id: string | number,
  position: string | number,
  user_id: string | number
}

interface Lesson {
  Clase: {
    course_id: string | number,
    descripcion: string,
    id: string | number,
    module_id: string | number,
    order: string | number,
    titulo: string
  },
  Quiz: any[],
  Recent: any[],
  Source: any[],
  course_id: string | number,
  descripcion: string,
  duration: string | number,
  durationInText: string,
  i_lesson: number,
  i_module: number,
  id: string | number,
  module_id: string | number,
  module_name: string,
  order: string | number,
  progress: string | number,
  progress_percentaje: number,
  slug: string,
  titulo: string
}

export interface Question {
  id: number;
  tipo: any;
  name: string;
  descripcion: string;
  feedback: string;
  tipo_respuesta: string;
  quiz_id: number;
  Answers: QuestionAnswer[];
  FilesQuestions: any[];
  Records?: any;
  index?: number;
  feedback_counter?: number;
  Evaluation?:string;
}

interface QuestionAnswer {
  id: number;
  file_name: string;
  file_url: string;
  file_ext: string;
  tipo: number | string;
  descripcion: string;
  question_id: number;
}


export interface Upload {
  class_info: {
    id: string | number,
    name: string
  },
  course_class_source_id_date: string,
  date_time: string,
  files: [
    {
      date_creation: string,
      description: string,
      state: string | number,
      type: string,
      language: string,
      file_name: string,
      link: string
    }
  ],
  name: string,
  source_info: {
    id: string | number,
    is_video: string | number,
    name: string,
    type: string | number,
  },
  state: string | number,
  type: string | number,
  user_id: string | number,
  position: number
}

