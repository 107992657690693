import { InjectionToken } from '@angular/core';

export type LAZY_MODULES = {
  graphQLModule: string;
};

export const lazyMap: LAZY_MODULES = {
    graphQLModule: 'src/app/shared/graphql/graphql.module#GraphQLModule'
};

export const LAZY_MODULES_MAP = new InjectionToken('LAZY_MODULES_MAP', {
  factory: () => lazyMap
});