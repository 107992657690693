import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'popup-msg',
  templateUrl: './popup-msg.component.html',
  styleUrls: ['./popup-msg.component.scss']
})
export class PopupMsgComponent implements OnInit {
  @Input() config: any = {};
  @Output() response = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
    if (this.config.show) {
      if (this.config.timer) {
        setTimeout(() => {
          this.config.show = false;
        }, this.config.timer);
      }
    }
  }

  confirm(type): void {
    this.config = {};
    this.response.emit(type);
  }

}
