import {
  Inject,
  Injectable,
  Injector,
  NgModuleFactory,
  NgModuleRef,
  Type,
  createNgModule,
  // NgModuleFactoryLoader,
} from "@angular/core";

import {
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  Router,
  Event,
  NavigationCancel,
  NavigationError,
} from "@angular/router";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";

//graphql
import { Subscription } from "rxjs";
import { LAZY_MODULES_MAP } from "./shared/graphql/lazy_module_map";
import { Apollo } from "apollo-angular";
//graphql

@Injectable()
export class AppService {

  public url_route: string = "";
  public url_Base: string = "https://edutin.com";
  public show_desktop_notification: boolean = false;
  public display_spinner: boolean = false;
  baseLambdaUrl = "https://api.edutin.com/b/p";
  baseRetry: number = 4;

  //graphql
  public ws_host = "tcm5oi5qdveqpamqp4txivk54q.appsync-api.us-east-1.amazonaws.com";
  public token: string;
  static querySubscription: Subscription;
  static idtoken: string;
  static current_opened_conversation: string;
  //graphql

  public cognitoUserAuthenticated;
  public verifyEmail = {
    detail: null,
    type: null,
  };

  public authenticated_error: string;

  public loadNavBar: boolean = false;
  public loadFooter: boolean = false;

  public indexedDB_users_list;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector,
    // private loader: NgModuleFactoryLoader,
    @Inject(LAZY_MODULES_MAP) private modulesMap
  ) {
    let routerEventsSubscriber = this.router.events.subscribe(
      (routerEvent: Event) => {
        if (routerEvent instanceof NavigationStart) {
          this.display_spinner = true;
        }

        if (
          routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationError ||
          routerEvent instanceof NavigationCancel
        ) {
          this.display_spinner = false;
          if (routerEventsSubscriber) routerEventsSubscriber.unsubscribe();
        }
      }
    );
  }

  public chat_user: any;
  public chatroom_state: boolean = false;
  public certification: any;
  public config = {
    actions: false, //si el usuario está logueado ? true : false
  };

  public display_navbar: boolean = true;
  public isDesktop: boolean = true;
  menu_is_active: boolean = false;
  public hoverTop(ev?) {
    if (this.url_route == "classroom") {
      this.menu_is_active = ev != null ? ev : false;
      if (!this.display_navbar) {
        this.display_navbar = true;
        // setTimeout(() => {
        if (!this.menu_is_active) this.display_navbar = false;
        // }, 500);
      } else {
        // setTimeout(() => {
        if (!this.menu_is_active) this.display_navbar = false;
        // }, 500);
      }
    }
  }

  getHeaders(): any {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    });
    return { headers: headers };
  }

  post(url: any, formData: FormData) {
    return this.http.post(url, formData, this.getHeaders());
  }

  put(url: any, formData: FormData) {
    return this.http.put(url, formData, this.getHeaders());
  }

  delete(url: any) {
    return this.http.delete(url, this.getHeaders());
  }

  public user_data: any;
  public user_session: number = -1;

  getUserData() {
    if (this.cognitoUserAuthenticated) {
      if (this.cognitoUserAuthenticated.hasOwnProperty("signInUserSession")) {
        return {
          valid: true,
          print: this.user_data,
          session: this.user_session,
        };
      } else {
        // User without session
        return { valid: true, print: null, session: this.user_session };
      }
    } else {
      // Error cognitoUserAuthenticated
      return { valid: false, print: null, session: this.user_session };
    }
  }

  public display_verification_alert: boolean = false; //Cuando es verdadero muestra la notificación de verificación de correo electrónico
  public userSignUpEmail;
  setUserData(user, from?) {
    this.cognitoUserAuthenticated = user;
    if (this.cognitoUserAuthenticated) {
      if (this.cognitoUserAuthenticated.hasOwnProperty("signInUserSession")) {
        user["userKeyPrefix"] = user.keyPrefix + "." + user.username;
        const { email_verified, certification_id } =
          user.signInUserSession.idToken.payload;
        user["email_verified"] = email_verified;

        this.cognitoUserAuthenticated = user;
        this.token = user.signInUserSession.idToken.jwtToken;
        AppService.idtoken = this.token;
        let user_data = JSON.parse(
          JSON.stringify(
            this.cognitoUserAuthenticated.signInUserSession.idToken.payload
          )
        );
        // User with session
        this.user_session = 1;

        let urlavatar = "https://d3puay5pkxu9s4.cloudfront.net/Users/";
        user_data.picture =
          user_data.image == ""
            ? urlavatar +
              (user_data.role == "propietario"
                ? "professor/medium_imagen.jpg"
                : "default/medium_imagen.jpg")
            : urlavatar + user_data.id + "/medium_" + user_data.image;
        this.user_data = user_data;
        if (
          user_data.hasOwnProperty("certification_id") &&
          user_data.certification_id == "0"
        ) {
          const ls_certification_id = localStorage.getItem(
            "last-certificate-default"
          );
          if (ls_certification_id) {
            this.user_data.certification_id = ls_certification_id;
          }
        }
      } else {
        // User without session
        this.user_session = 0;
        this.user_data = [];
      }
    } else {
      this.token = null;
    }
  }

  popUpData: any = {};
  public reloadGetsNavBar: boolean = true;
  public startGetsNavBar() {
    return new Promise((resolve) => {
      // Get user data
      const userData = this.getUserData();
      if (userData.valid && this.reloadGetsNavBar) {
        // init app
        resolve(true); // Start the validations of the routes
        if (this.user_session == 1) {
          // User with session
        } else if (this.user_session == 0) {
          // User without session
        }
        this.reloadGetsNavBar = false;
      } else {
        resolve(false);
      }
    });
  }

  //graphql lazy loading
  _apolloClient;
  // loadGraphQLModule() {
  //   this.loader
  //     .load(this.modulesMap["graphQLModule"])
  //     .then((moduleFactory: NgModuleFactory<any>) => {
  //       const moduleRef = moduleFactory.create(this.injector);
  //       const nuwModule = moduleRef.injector.get(
  //         (<any>moduleFactory.moduleType).createApolloInit
  //       );
  //       this._apolloClient = moduleRef.injector.get(
  //         (<any>moduleFactory.moduleType).apolloClient
  //       );
  //       //console.log(this._apolloClient);
  //     });
  // }
  //graphql lazy loading

  public loadGraphQLModule() {
    return new Promise(resolve => {
        import('./shared/graphql/graphql.module').then((importedFile) => {
            const componentToOpen = importedFile.GraphQLModule.createApolloInit;
            this.injectModuleRef(importedFile, componentToOpen);
            // resolve(true);
        }).catch((err) => {
            //   this.loadApolloClientSubject.next(false);
            //   resolve(false);
        });
    })
}

injectModuleRef<T>(importedFile: T, componentToOpen) {

    const module: Type<T> = (<any>importedFile)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<any> = createNgModule(module, this.injector);
    this._apolloClient = moduleRef.injector.get(Apollo);
}

  public defaultGetsNavBar() {
    this.user_data = undefined;
    this.user_session = -1;

    this.reloadGetsNavBar = true;
  }

  changeStorage(e) {
    if (e.key == "token") {
      let url = new URL(window.location.href);
      if (url.searchParams.get("token") != null) {
        this.token = url.searchParams.get("token");
        localStorage.setItem("token", this.token);
      } else if (localStorage.getItem("token") != null) {
        if (e.oldValue == e.newValue) {
          this.token = localStorage.getItem("token");
        } else {
          sessionStorage.clear();
          window.location.href = "https://edutin.com/";
        }
      } else {
        // console.log("ejecución de eliminación");
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "https://edutin.com/users/login";
      }
    }
  }

  public learning: Array<any> = [];
  loadingLearning: boolean = false;
  learningSubject: Subject<any> = new Subject<any>();
  pagination: number = 0;
  loadingPagination: boolean = false;
  getLearning(page): Observable<any> {
    return this.http.get(this.baseLambdaUrl + "/certifications?page=" + page + '&order_by="fecha"',
      this.getHeaders()).pipe(map((response: any) => {
          // Validar datos
          let valData = this.valData(2, response);
          if (valData.is) {
            response.data = response.data.map((item) => {
              let certification = {
                avance: item.Certification.progress,
                calificacion: item.Certification.calification,
                certification_id: item.Certification.id,
                certification_state: item.Certification.estado,
                certification_tour: item.Certification.tour,
                clase_id: item.Certification.clase_id,
                id: item.Course.id,
                language_id: item.Course.language_id,
                language: item.Course.language_id == 1 ? "Español" : "Inglés",
                name: item.Course.nombre,
                quiz_id: item.Certification.quiz_id,
                rating: item.Certification.rating,
                slug: item.Course.slug,
                source_id: item.Certification.source_id,
                state: item.Course.estado,
                time: item.Certification.duracion,
                tipo: item.Course.tipo,
                visitas: item.Course.visitas,
                category_id: item.Course.category_id,
                fecha: item.Certification.fecha,
                fecha_inscripcion: item.Certification.fecha_inscripcion,
                date: "",
                date_state: false,
                pending_payment: false,
              };
              certification = Object.assign(
                certification
              );
              return Object.assign(item.Course, certification);
            });
            return { valid: true, print: response.data };
          } else {
            return { valid: false, print: valData.msg };
          }
        }),
        retry(this.baseRetry + 6),
        catchError(this.handleError<any>("getLearning", []))
      );
  }

  public updateLearningAvance(course) {
    let i = this.learning.findIndex((elemt) => elemt.id == course.id);
    if (i != -1) {
      let temp = this.learning[i];
      this.learning = this.learning
        .filter((item) => item.id != course.id)
        .map((item) => {
          item.active = false;
          return item;
        });
      this.learning.unshift(Object.assign(temp, { active: true }));
    } else {
      // Course of config not found in getLearning()
    }
  }

  enrollUser(body): Observable<any> {
    return this.post(this.baseLambdaUrl + '/certifications', body).pipe(
      map((response: any) => {
        // Validar datos
        let valData = this.valData(2, response);
        if (valData.is) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: valData.msg };
        }
      }),
      retry(this.baseRetry + 6),
      catchError(this.handleError<any>('setRanking', []))
    );
  }

  putUser(body): Observable<any> {
    return this.put(this.baseLambdaUrl + "/users/" + this.user_data.id, body).pipe(
      map((response: any) => {
        // Validar datos
        let valData = this.valData(2, response);
        if (
          valData.is ||
          (response.hasOwnProperty("status") && response.status)
        ) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: valData.msg };
        }
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("getUnit", []))
    );
  }

  isJsonString(strJson: string): boolean {
    // Validar JSON
    try {
      JSON.parse(strJson);
    } catch (e) {
      return false;
    }
    return true;
  }

  isError(resData: any, errorName: string): any {
    // Validar datos de JSON, Error...
    if (this.isJsonString(JSON.stringify(resData))) {
      if (resData == null || resData == undefined) {
        return { is: false, msg: "Answer with error" };
      } else if (resData instanceof Array) {
        return { is: true, msg: "Ok Array" };
      } else if (resData.hasOwnProperty("data")) {
        if (resData.data == null || resData.data == undefined) {
          return { is: false, msg: "Answer with error" };
        } else if (resData.data instanceof Array) {
          return { is: true, msg: "Ok Array" };
        } else if (resData.data.hasOwnProperty("Status")) {
          if (resData.data.Status == false) {
            // console.log("ejecución de eliminación");
            localStorage.clear();
            sessionStorage.clear();
            // window.location.href = this.url_Base + "/users/login";
            return { is: false, msg: "Answer with error" };
          } else {
            return { is: true, msg: "Ok Value" };
          }
        } else {
          return { is: true, msg: "Ok Value" };
        }
      } else {
        return { is: true, msg: "Ok Value" };
      }
    } else {
      return { is: false, msg: "Invalid json response" };
    }
  }

  isErrorLambda(resData: any): any {
    if (this.isJsonString(JSON.stringify(resData))) {
      if (resData == null || resData == undefined) {
        return { is: false, msg: "Respond with null or undefined" };
      } else if (resData.hasOwnProperty("status")) {
        if (resData.status) {
          if (resData.hasOwnProperty("data")) {
            return { is: true, msg: "Ok Value" };
          } else {
            return {
              is: false,
              msg: "Response does not contain the [data] property",
            };
          }
        } else {
          return {
            is: false,
            msg: "Response contains false value in [status] property",
          };
        }
      } else {
        return {
          is: false,
          msg: "Response does not contain the [status] property",
        };
      }
    } else {
      return { is: false, msg: "Response does not contain a valid json" };
    }
  }

  valData(type: number, params: any): any {
    switch (type) {
      case 0:
        // Default
        return this.isError(params.x, "Error");
      case 1:
        return this.isError(params.x, params.y);
      case 2:
        return this.isErrorLambda(params);
      default:
        return {
          is: false,
          msg: "Invalid parameter type:(" + type + ") in valData",
        };
    }
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    // console.log(`ClassroomService: ${message}`);
  }
}
