import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: "dateFormat" })
export class DateFormat implements PipeTransform {
    transform(_date: Date): any {
        let date = new Date(_date);
        let year = date.getUTCFullYear();

        return year;
    }
}

@Pipe({ name: "capitalizePipe" })
export class CapitalizePipe implements PipeTransform {
    transform(str: string): any {

        const str2 = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

        return str2;
    }
}

@Pipe({ name: "flagPipe" })
export class FlagPipe implements PipeTransform {
    transform(short_name: string): any {

        // const str2 = `https://countryflags.io/${short_name}/flat/32.png`;
        const str2 = `https://d3puay5pkxu9s4.cloudfront.net/icons/flags/4x3/${short_name.toLocaleLowerCase()}.svg`;

        return str2;
    }
}

@Pipe({ name: "formatPhoneNumber" })
export class FormatPhoneNumber implements PipeTransform {
    transform(phone: any, code: any): any {

        let phone_number;
        if (code) {
            let country_code = code.replace(/-/g, '');
            phone_number = phone.split('+' + country_code)[phone.split('+' + country_code).length - 1];
        } else {
            phone_number = phone
        }

        return phone_number;
    }
}



@Pipe({ name: "sortByCode" })
export class SortByCode implements PipeTransform {
    transform(array: any[], field?: any): any[] {
        array.sort(function (a, b) {
            return (parseInt(a.code) < parseInt(b.code)) ? -1 : (parseInt(a.code) > parseInt(b.code)) ? 1 : 0;
        });
        return array;
    }
}


@Pipe({ name: 'cutUserName' })
export class CutUserNamePipe implements PipeTransform {
    transform(username: string, length: string): string {

        username = username.toLocaleLowerCase();
        let split_username_length = username.trim().split(/\s+/).length;
        var un = username.split(' ');
        switch (length) {
            case '1':
                var user_name = un[0];
                break;
            case '2':
                var user_name = split_username_length < 3 ? username : split_username_length == 3 ? (un[0] + ' ' + un[1]) : (un[0] + ' ' + un[2]);
                break;
        }
        let name = '';
        user_name.split(' ').forEach(U => {
            name += (U.charAt(0).toUpperCase() + U.slice(1)) + ' ';
        });

        return name;

    }
}



@Pipe({ name: "sortByOrder" })
export class SortByOrderPipe implements PipeTransform {
  transform(array: any[], field?: any): any[] {
    array.sort((a, b) => {
      return (a.order > b.order) ? -1 : (a.order < b.order) ? 1 : 0;
    });
    return array;
  }
}
