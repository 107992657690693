import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input('appTooltip') tooltipText: string;
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top'; // Agregamos un nuevo parámetro para la posición del tooltip
  private tooltip: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    if (!this.tooltip && this.tooltipText) {
      this.tooltip = this.renderer.createElement('div');
      this.renderer.addClass(this.tooltip, 'tooltip');
      this.renderer.addClass(this.tooltip, this.tooltipPosition ? 'bottom' : this.tooltipPosition);
  
      const tooltipTextLines = this.tooltipText.split('\n'); // Dividir el texto en líneas
      for (const line of tooltipTextLines) {
        const lineElement = this.renderer.createElement('div'); // Crear un elemento <div> para cada línea
        this.renderer.appendChild(lineElement, this.renderer.createText(line)); // Agregar el texto de la línea
        this.renderer.appendChild(this.tooltip, lineElement); // Agregar el elemento de línea al tooltip
      }
  
      this.renderer.appendChild(document.body, this.tooltip);
  
      const hostRect = this.elementRef.nativeElement.getBoundingClientRect();
      const tooltipRect = this.tooltip.getBoundingClientRect();

      let top, left;

      switch (this.tooltipPosition) {
        case 'bottom':
          top = hostRect.bottom + 10;
          left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
          break;
        case 'left':
          top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
          left = hostRect.left - tooltipRect.width - 10;
          break;
        case 'right':
          top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
          left = hostRect.right + 10;
          break;
        default: // Por defecto, posición 'top'
          top = hostRect.top - tooltipRect.height - 10;
          left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
          break;
      }

      this.renderer.setStyle(this.tooltip, 'top', top + 'px');
      this.renderer.setStyle(this.tooltip, 'left', left-5 + 'px');
    }
  }

  private hideTooltip() {
    if (this.tooltip && this.tooltipText) {
      // Agregamos la clase 'tooltip-hidden' para aplicar la animación de salida
      this.renderer.addClass(this.tooltip, 'tooltip-hidden');

      // Eliminamos la clase 'tooltip' para evitar la animación de entrada al mostrar nuevamente el tooltip
      this.renderer.removeClass(this.tooltip, 'tooltip');

      // Eliminamos el tooltip del DOM después de que finalice la animación de salida
      setTimeout(() => {
        this.renderer?.removeChild(document.body, this.tooltip);
        this.tooltip = null;
      }, 150);
    }
  }
}