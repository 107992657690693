import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {

  indexedDB = window.indexedDB;
  db;

  constructor(
    public router: Router,
  ) {

  }


  openIndexedDB = () => new Observable((observer) => {

    if (this.indexedDB) {

      const request = indexedDB.open('edutindb', 1);
      request.onsuccess = () => {
        this.db = request.result;
        observer.next({ status: 'ok' });
      }

      request.onupgradeneeded = () => {
        //Todas las funciones de creación,lectura, edición y eliminación de base de datos se realiza aquí
        this.db = request.result;
        const objectStore = this.db.createObjectStore('users', { keyPath: 'id' });

      }

      request.onerror = (error) => {
        console.log('Error: ', error);
        observer.next({ status: 'error' });
      }
    }

  })


  // let new_user = {
  //   id: 3228218,
  //   username: 'stevenedutin3@gmail.com',
  //   name: 'Steven Edutin3',
  //   image: 'image3.jpg',
  //   fuente: 'form'
  // }


  addUser = (new_user) => new Observable((observer) => {

    const transaction = this.db.transaction(['users'], 'readwrite');
    const objectStore = transaction.objectStore('users');
    const request = objectStore.add(new_user);

    request.onsuccess = (e) => {
      observer.next({ status: 'ok' });

    }

    request.onerror = (error) => {
      console.log('Error: ', error);
      observer.next({ status: 'error' });
    }

  })


  getUserList = () => new Observable((observer) => {

    let users = [];

    // if(this.db)

    try {
      const transaction = this.db.transaction(['users'], 'readonly');
      const objectStore = transaction.objectStore('users');
      const request = objectStore.openCursor(); //metodo que permite leer la información

      request.onsuccess = (e) => {
        const cursor = e.target.result;

        if (cursor) {
          // console.log('lectura: ', cursor.value);
          cursor.continue(); //Siempre que haya algo en cursor debe continuar
          users.push(cursor.value);

        } else {

          observer.next({ status: 'ok', data: users });

        }


      }

      request.onerror = (error) => {
        console.log('Error: ', error);
        observer.next({ status: 'error' });
      }

    }
    catch (e) {

      observer.next({ status: 'ok', data: [] });

      // setTimeout(()=>{
      //   this.router.navigate(['/user/login']);
      // }, 250)

    }




  });


  getUser = (user_id) => new Observable((observer) => {

    const transaction = this.db.transaction(['users'], 'readwrite');
    const objectStore = transaction.objectStore('users');
    const request_ = objectStore.get(user_id);

    request_.onsuccess = (e) => {

      if (request_.result) {
        //User encontrado
        let user = request_.result;

        observer.next({ status: 'ok', user });

      } else {

        //User no
        observer.next({ status: 'not_found' });

      }

      // return request_.result;
    };

  });




  updateUser = (data) => new Observable((observer) => {

    const transaction = this.db.transaction(['users'], 'readwrite');
    const objectStore = transaction.objectStore('users');
    const request_ = objectStore.put(data);
    request_.onsuccess = (e) => {
      // readData();
      observer.next({ status: 'ok' });
    }


  });


  deleteUser = (user_id) => new Observable((observer) => {

    const transaction = this.db.transaction(['users'], 'readwrite');
    const objectStore = transaction.objectStore('users');
    const request = objectStore.delete(user_id);

    request.onsuccess = () => {
      observer.next({ status: 'ok' });
    }

    request.onerror = (error) => {
      console.log('Error: ', error);
      observer.next({ status: 'error' });
    }

  })


  randomCode(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  // console.log(makeid(5));

  cipher(pass) {
    // return this.randomCode(10) + window.btoa(pass);
    return this.randomCode(10) + encodeURIComponent(window.btoa(pass));
  }

  decipher(pass) {
    let password = decodeURIComponent(pass).slice(10, pass.length);
    return window.atob(password);
    // return  decodeURIComponent(password);
  }


}
