import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-custom-image',
  templateUrl: './custom-image.component.html',
  styleUrls: ['./custom-image.component.scss','../../../styles/font-awesome.scss'],
})
export class CustomImageComponent implements OnInit {
  @Input() src: string;
  @Input() alt: string;
  @Input() type: string;
  @Input() name: string = 'default';
  @Input() size: string;
  @Input() randomNumber: number

  constructor() { }

  ngOnInit(): void {
    if (!this.randomNumber) {
      this.randomNumber = this.getRandomNumber();
    }
  }

  getUniqueColor(name: string): string {
    let hash = 0;

    // Calcula un valor hash basado en el nombre
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    // Genera colores pasteles menos intensos en formato hexadecimal
    const minValue = 150;
    const maxValue = 240;
    const range = maxValue - minValue;
    
    const r = (hash % range) + minValue;
    const g = ((hash >> 8) % range) + minValue;
    const b = ((hash >> 16) % range) + minValue;
  
    const color = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
    return color;
  }

  getRandomNumber(): number {
    return Math.floor(Math.random() * 100);
  }

  getImageSource(src: any): string {
    return `https://s3.amazonaws.com/edutin-publico/courses/${src.id}/img/web/${src.image}`;
  }

  handleImageError(img: HTMLImageElement, src: any): void {
    img.src = `https://s3.amazonaws.com/edutin-publico/courses/${src.id}/img/web/${src.mobile_image}`;
    img.onerror = () => {
      img.src = `https://s3.amazonaws.com/edutin-publico/curso/${src.id}/card_imagen.jpg`;
      img.onerror = () => {
        img.src = 'https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg';
      };
    };
  }
}
