import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SharedService } from "./shared.service";
import {
  CutUserNamePipe,
  GetProgress,
  RoleName,
  UserClassName,
  UrlImage,
  shoppingCarType,
  notificationsDate,
  messageDate,
  categoryName,
  ImageUrlPipe,
} from "./shared.pipe";
import { ClickOutsideDdownDirective } from "./directives/clickOutsideDdown.directive";
import { RouterModule } from "@angular/router";
import { PopupMsgComponent } from "./components/popup-msg/popup-msg.component";
import { PopUpsComponent } from "./components/pop-ups/pop-ups.component";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { CustomImageComponent } from "./components/custom-image/custom-image.component";
import { ComboboxComponent } from "./components/combobox/combobox.component";
import { TooltipDirective } from "./directives/toolTip.directive";
//import { FrequentQuestionsComponent } from './components/frequent-questions/frequent-questions.component';

@NgModule({
  declarations: [
    CutUserNamePipe,
    GetProgress,
    RoleName,
    UserClassName,
    UrlImage,
    shoppingCarType,
    notificationsDate,
    messageDate,
    categoryName,
    ClickOutsideDdownDirective,
    PopupMsgComponent,
    PopUpsComponent,
    SearchBarComponent,
    CustomImageComponent,
    ComboboxComponent,
    TooltipDirective,
    PopUpsComponent,
    //FrequentQuestionsComponent,
    ImageUrlPipe
  ],
  imports: [FormsModule, CommonModule, RouterModule],
  exports: [
    ClickOutsideDdownDirective,
    PopupMsgComponent,
    //FrequentQuestionsComponent,
    categoryName,
    SearchBarComponent,
    CustomImageComponent,
    ComboboxComponent,
    TooltipDirective,
    PopUpsComponent
  ],
  providers: [SharedService],
})
export class SharedModule {}
