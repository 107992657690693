<div class="content-academy">

  <div class="alert" *ngIf="_appService.display_verification_alert && !user_routes.includes(_appService.url_route)">
    <div>
      <p>
        Es necesario <a (click)="verifyAuthenticatedUserEmail()">verificar su correo electrónico</a>
      </p>
    </div>

    <span class="icon-close" (click)="_appService.display_verification_alert=false"></span>
  </div>

  <div class="app-content">
    <router-outlet></router-outlet>

    <div class="spinner" *ngIf="_appService.display_spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

  </div>
  <pop-ups></pop-ups>

</div>
