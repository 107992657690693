import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'getProgress' })
export class GetProgress implements PipeTransform {
  transform(progress: any): string {
    return progress.avance + "%";
  }
}

@Pipe({ name: 'roleName' })
export class RoleName implements PipeTransform {
  transform(role: string): string {
    switch (role.toLocaleLowerCase()) {
      case "free":
        return "Estudiante libre";
      case "premium":
        return "Estudiante oficial";
      case "propietario":
        return "Profesor";
      case "moderador":
      case "monitor":
        return "Monitor";
      default:
        return role
    }
  }
}

@Pipe({ name: 'urlImage' })
export class UrlImage implements PipeTransform {
  transform(item: any, type: number): string {
    let url = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
    if (type == 0) {
      // Formato normal
      if (item.sender.fuente == 'facebook' || item.sender.fuente == 'google') {
        if (item.sender.image != '') {
          if (item.sender.image.substring(0, 4) == 'http') {
            url = item.sender.image;
          } else {
            url += item.sender.id + '/small_' + item.sender.image;
          }
        } else {
          url += 'default/small_imagen.jpg';
        }
      } else if (item.sender.image == '') {
        url += 'default/small_imagen.jpg';
      } else {
        url += item.sender.id + '/small_' + item.sender.image;
      }
    } else if (type == 1) {
      // Formato mapeado
      if (item.associated[0].user.user_fuente == 'facebook' || item.associated[0].user.user_fuente.S == 'google') {
        if (item.associated[0].user.user_image != '') {
          if (item.associated[0].user.user_image.substring(0, 4) == 'http') {
            url = item.associated[0].user.user_image;
          } else {
            url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
          }
        } else {
          url += 'default/small_imagen.jpg';
        }
      } else if (item.associated[0].user.user_image == '') {
        url += 'default/small_imagen.jpg';
      } else {
        url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
      }
    } else if (type == 2) {
      // Formato cursos
      if (item.User.fuente == 'facebook' || item.User.fuente == 'google') {
        if (item.User.image != '') {
          if (item.User.image.substring(0, 4) == 'http') {
            url = item.User.image;
          } else {
            url += item.User.id + '/small_' + item.User.image;
          }
        } else {
          url += 'default/small_imagen.jpg';
        }
      } else if (item.User.image == '') {
        url += 'default/small_imagen.jpg';
      } else {
        url += item.User.id + '/small_' + item.User.image;
      }
    } else if (type == 3) {
      // Formato mapeado lambda 
      if (item.associated[0].user.user_fuente == 'facebook' || item.associated[0].user.user_fuente == 'google') {
        if (item.associated[0].user.user_image != '') {
          if (item.associated[0].user.user_image.substring(0, 4) == 'http') {
            url = item.associated[0].user.user_image;
          } else {
            url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
          }
        } else {
          url += 'default/small_imagen.jpg';
        }
      } else if (item.associated[0].user.user_image == '') {
        url += 'default/small_imagen.jpg';
      } else {
        url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
      }
    }
    return url;
  }
}

@Pipe({ name: 'userClassName' })
export class UserClassName implements PipeTransform {
  transform(role: string): string {
    switch (role.toLocaleLowerCase()) {
      case "free":
        return "student-free";
      case "premium":
        return "student-official";
      case "monitor":
        return "monitor";
      case "propietario":
        return "teacher";
      case "moderador":
        return "moderador";
    }
  }
}


@Pipe({ name: 'cutUserName' })
export class CutUserNamePipe implements PipeTransform {
  transform(username: string, length: string): string {

    username = username.toLocaleLowerCase();

    let split_username_length = username.trim().split(/\s+/).length;
    var un = username.split(' ');
    switch (length) {
      case '1':
        var user_name = un[0];
        break;
      case '2':
        var user_name = split_username_length < 3 ? username : split_username_length == 3 ? (un[0] + ' ' + un[1]) : (un[0] + ' ' + un[2]);
        break;
    }
    let name = '';

    user_name.split(' ').forEach(U => {
      name += (U.charAt(0).toUpperCase() + U.slice(1)) + ' ';
    });

    return name;

  }
}

@Pipe({ name: 'shoppingCarType' })
export class shoppingCarType implements PipeTransform {
  transform(type: number): string {
    switch (type) {
      case 1:
        return 'Certificado de estudios';
      case 2:
        return 'Carrera online';
    }
  }
}

@Pipe({ name: 'notificationsDate' })
export class notificationsDate implements PipeTransform {
  transform(_date: string): string {
    var today: any = new Date();
    var yesterday = "";
    var dd = String(today.getDate()).padStart(2, '0');
    var dd1 = String(today.getDate() - 1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    yesterday = yyyy + '-' + mm + '-' + dd1;

    if (_date.substring(0, 10) == today) {
      return 'Hoy';
    } else if (_date.substring(0, 10) == yesterday) {
      return 'Ayer';
    } else {
      var monthNames = [
        "Ene", "Feb", "Mar",
        "Abr", "May", "Jun", "Jul",
        "Ago", "Sep", "Oct",
        "Nov", "Dic"
      ];
      return parseInt(_date.substring(8, 10)) + ' ' + monthNames[parseInt(_date.substring(5, 7)) - 1];
    }
  }
}

@Pipe({ name: 'messageDate' })
export class messageDate implements PipeTransform {
  transform(_date: string): string {
    var today: any = new Date();
    var yesterday = "";
    var dd = String(today.getDate()).padStart(2, '0');
    var dd1 = String(today.getDate() - 1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    yesterday = yyyy + '-' + mm + '-' + dd1;


    if (_date.substring(0, 10) == today) {
      return 'Hoy';
    } else if (_date.substring(0, 10) == yesterday) {
      return 'Ayer';
    } else {
      var monthNames = [
        "Ene", "Feb", "Mar",
        "Abr", "May", "Jun", "Jul",
        "Ago", "Sep", "Oct",
        "Nov", "Dic"
      ];

      return parseInt(_date.substring(8, 10)) + ' ' + monthNames[parseInt(_date.substring(5, 7)) - 1];
    }
  }
}

@Pipe({ name: 'categoryName' })
export class categoryName implements PipeTransform {
  transform(categoryid: any, categories: any): string {
    if (categoryid && categories) {
      let result = categories.find(category => category.id == categoryid);
      return result ? result.name : '';
    }
    return '';
  }
}

@Pipe({
  name: "imageUrlPipe",
})
export class ImageUrlPipe implements PipeTransform {
  transform(src: any, size: string = "small"): string {
    // Verificar si src es un enlace directo a la imagen
    if (src.image) {
      if (src.image.startsWith("http") || src.image.startsWith("https")) {
        return src.image;
      }
    }

    // Verificar si src es un objeto con id e image
    if (src && src.id && src.image) {
      const baseUrl = "https://d3puay5pkxu9s4.cloudfront.net/Users/";
      const imageSize = size === "very-large" ? "medium_" : "small_";
      return `${baseUrl}${src.id}/${imageSize}${src.image}`;
    }

    // Si no se cumple ninguna de las condiciones anteriores, devolver una URL por defecto o un valor nulo según tus necesidades.
    // Por ejemplo:
    // return 'URL_POR_DEFECTO';
    // return null;

    // En este ejemplo, se devuelve una URL de imagen por defecto si no se cumple ninguna condición.
    return "https://d3puay5pkxu9s4.cloudfront.net/Users/default/small_imagen.jpg";
  }
}