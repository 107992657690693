import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IndexedDBService } from 'src/app/auth/services/indexedDB.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private _router: Router,
    private _appService: AppService,
    private _authService: AuthService,
    private _indexedDBService: IndexedDBService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    //('%c =====:: state url ::===== ', 'color:black; background:red', state.url);
    // Hide nav bar and footer
    this._appService.loadNavBar = this._appService.loadFooter = false;
    // Identify error in main route
    if (state.url.includes('error_description')) {
      if (state.url.includes('ERR01')) {
        this._appService.authenticated_error = 'ERR01';
      }
      if (state.url.includes('access_token')) {
        this._authService.loginLearn().subscribe();
      }
    }
    // Child routes
    switch (state.url) {
      case '/user':
        // console.log("user");
        break;
      case '/user/login':
        // Init gets nav bar

        if (this._appService.cognitoUserAuthenticated != null && this._appService.cognitoUserAuthenticated.hasOwnProperty('signInUserSession')) {
          this._router.navigate(['/home']);
        } else {
          this._router.navigate(['/user/accounts']);

          // console.log('usuario no está logeado')
          // this._indexedDBService.getUserList().subscribe((resp: any) => {
          //   console.log('usuarios db: ', resp);
          //   this._appService.indexedDB_users_list = JSON.parse(JSON.stringify(resp.data));

          //   if (resp.status == 'ok' && resp.data.length > 0) {
          //   }

          // });

        }

        this._appService.startGetsNavBar();
        break;
      case '/user/accounts':


        break;

      case '/user/add':
        break;
    }


    if (state.url.includes('/user/add') && !state.url.includes('/user/add_next')) {

      if (next.queryParams.hasOwnProperty('username')) {

        let username = next.queryParams['username'];

        this._router.navigate(['/user/add_next'], { queryParams: { username } });

      } else {

      }
    }



    if (state.url.includes('/user/accounts')) {


      let user = null;
      let url_redirect = null;

      if (next.queryParams.hasOwnProperty('user')) {

        user = next.queryParams['user'];

        user = JSON.parse(window.atob(user));
        // this._router.navigate(['/user/login'], { queryParams: { username: username } });

        if (next.queryParams.hasOwnProperty('url_redirect')) {

          url_redirect = next.queryParams['url_redirect'];

        }


      }



      if (this._appService.cognitoUserAuthenticated != null && this._appService.cognitoUserAuthenticated.hasOwnProperty('signInUserSession')) {

        //Si hay una sesión iniciada


        if (user != null && url_redirect != null) {

          if (user.id == this._appService.cognitoUserAuthenticated.signInUserSession.idToken.payload.id) {
            //('%c ==: ES EL MISMO USUARIO!!! :==', 'color:black; background:yellow');
            window.location.href = url_redirect.replaceAll("'", '');
          } else {
            //('%c ==: NOOO ES EL MISMO USUARIO!!! :==', 'color:white; background:red');
            if (user.username == '') {
              delete user.username;
            }

            if (user.fuente == '') {
              if (this._authService.validatePhoneNumber(user.username)) {
                user.fuente = 'phone';
              } else {
                user.fuente = 'email';
              }
            }

            this._authService.signOut();

          }

        } else {
          this._router.navigate(['/home']);
        }



      } else {
        //Si no hay sesión iniciada
        this._indexedDBService.getUserList().subscribe((resp: any) => {

          this._appService.indexedDB_users_list = JSON.parse(JSON.stringify(resp.data));

          if (resp.status == 'ok' && resp.data.length > 0) {

            //Si hay más usuarios en la lista de usuarios
            // this._router.navigate(['/user/accounts']);

          } else {

            //Si no hay cuentas en la lista

            if (user != null) {

              if (user.username == '') {
                delete user.username;
              }

              if (user.fuente == '') {
                if (this._authService.validatePhoneNumber(user.username)) {
                  user.fuente = 'phone';
                } else {
                  user.fuente = 'email';
                }
              }

              this._router.navigate(['/user/accounts'], { queryParams: { url_redirect, user: window.btoa(JSON.stringify(user)) } });
              // this._router.navigate(['/user/accounts']);

            } else {
              this._router.navigate(['/user/login']);
            }

          }

        });

      }

    }


    return true;
  }
}
