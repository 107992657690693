import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APP_ROUTING } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStategy } from './custom-route-reuse-strategy';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    HttpClientModule,
    SharedModule
  ],
  providers: [AppService, {
    provide: RouteReuseStrategy,
    useClass: CustomRouteReuseStategy,
  },
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {

}
