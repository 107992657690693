<div class="group-container" *ngIf="type === 'group'">
    <img class="group-image" *ngIf="src" #img
        [src]="'https://d3puay5pkxu9s4.cloudfront.net/'+src+'?a=' + randomNumber"
        (error)="img.src='https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg'">
    <div class="group-image icon-container" *ngIf="src == null" [style.background-color]="getUniqueColor(name)">
        <i class="icon fa fa-group"></i>
    </div>
</div>

<div class="user-container" *ngIf="type === 'user'"
    [ngClass]="{'medium': size === 'medium', 'large': size === 'large', 'very-small': size === 'very-small', 'very-large': size === 'very-large'}">
    <img class="user-image" #img [src]="src | imageUrlPipe : size"
        (error)="img.src= 'https://d3puay5pkxu9s4.cloudfront.net/Users/default/small_imagen.jpg'">
</div>

<div class="course-container" *ngIf="type === 'course'"
    [ngClass]="{'small': size === 'small', 'medium': size === 'medium', 'large': size === 'large'}">
    <ng-container *ngIf="!src?.onlyUrl">
        <img class="course-image" #img *ngIf="!src?.mobile_image" [src]="'https://d3puay5pkxu9s4.cloudfront.net/' + src.image_url + '?a=' + randomNumber"
            (error)="img.src='https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg'">
        <img class="course-image" #img *ngIf="src?.mobile_image"
            [src]="'https://d3puay5pkxu9s4.cloudfront.net/' + src.mobile_image + '?a=' + randomNumber"
            (error)="img.src='https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg'">
    </ng-container>
    <ng-container *ngIf="src?.onlyUrl">
        <img class="course-image" #img [src]="src?.image_url"
            (error)="img.src='https://d3puay5pkxu9s4.cloudfront.net/img/course_default.jpeg'">
    </ng-container>
</div>